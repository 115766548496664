<template>
    <div style="height: 960px;background: #F9FAFA;width:100%;border:1px solid #F9FAFA;">
        <div style="width:80.48%;margin: 52px auto;text-align:center;height: 856px;background: #FFFFFF;">
            <p style="margin:27px auto;padding-top:27px;font-size: 22px;font-weight: 500;">音乐授权范围</p>
            <table class="info">
                <th>作品信息</th>
                <th>被授权方</th>
                <th>授权方</th>
                <th>授权时间</th>
                <th>授权期限</th>
                <tr>
                    <td style="text-align:left;padding-left:10px;">
                        <p>名称：{{musicInfo.music_name}}</p>
                        <p>作者：{{musicInfo.suplr_name}}</p>
                        <p>ID：{{musicInfo.music_id}}</p>
                    </td>
                    <td>{{musicInfo.auth_name}}</td>
                    <td>北京火星火翎文化科技有限公司</td>
                    <td>{{musicInfo.create_at}}</td>
                    <td>永久
                        <a-tooltip style="cursor:pointer;" placement="bottom" title="VIP会员期间下载制作完成且首次公开发表的音乐作品可在授权范围内永久授权">
                            <img src="@/assets/images/web/introduce/ques.svg" />
                        </a-tooltip>
                    </td>
                </tr>
            </table>
            <p style="text-align:left;margin-bottom:12px;" class="info-title">音乐授权项</p>
            <table class="info-detail">
                <th>授权 [{{musicInfo.auth_name}}] 个人使用（非商用）</th>
                <th>未授权</th>
                <tr>
                    <td>
                        <img src="@/assets/images/web/introduce/yes.svg" />
                        <span>个人自媒体：自媒体视频、UP主创作、vlog视频（不包含商业内容的非盈利性视频）</span>
                    </td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>个人影视作品：个人独立电影、独立动画、纪录片</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="@/assets/images/web/introduce/yes.svg" />
                        <span>个人自媒体：自媒体视频、UP主创作、vlog视频（不包含商业内容的非盈利性视频）</span>
                    </td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>自媒体：公众号短视频、微博短视频、信息流短视频以及其他视频平台商用视频和PGC内容</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="@/assets/images/web/introduce/yes.svg" />
                        <span>教育用途：在校学生毕业设计或在校园内传播仅为教育目使用的音视频内容</span>
                    </td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>影视作品：院线电影、网络电影、电视连续剧、网剧、动画片、纪录片、综艺节目、相关的预告片、宣传片等</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <img src="@/assets/images/web/introduce/yes.svg" />
                        <span>家庭视频：电子相册、婚礼视频、生活视频等纪念性音视频内容</span>
                    </td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>游戏/APP内置：软件、APP、游戏、产品内置（如手机出厂预装铃声）</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>广播剧/有声读物：适用于在各渠道的有声书、广播剧等音频作品中使用</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>教育课程：学校、公开课、网络直播课、在线课程、教育平台等付费课程</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>商业直播：各类电商平台、MCN机构商业性质的网络直播</span>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <img src="@/assets/images/web/introduce/no.svg" />
                        <span>现场活动：各类线下活动、展览、年会、发布会、商业场所使用</span>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script>
  import {tooltip as ATooltip} from 'ant-design-vue';
  export default {
    data() {
      return {
        musicInfo: {},
        licensed_id: '',
      };
    },
    components: {
        ATooltip
    },
    created() {
      this.licensed_id = this.$route.params.licensed_id || '';
      this.getMusicInfo();
    },
    methods: {
        getMusicInfo() {
        this.$axios.VipAuthInfo({licensed_id: this.licensed_id}).then((res) => {
            const data = res.data;
            if (data && data.code == 0) {
              this.musicInfo = data.data || {};
              console.log("1111", this.musicInfo);
            }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
    .info, .info-detail, .info-title{
        width: 94.9%;
        margin: 0 auto;
    }
    th, tr, td{
        border: 1px solid #EBF0F2;
    }
    th{
        line-height: 45px;
        background: #F8FAFB;
    }
    .info {
        margin-bottom: 24px;
        th {
            width: 20%; 
        }
        td{
            height: 100px;
            text-align:center;
            p{
                margin: 0;
                padding: 0;
                line-height: 26px;
            }
        }
        td, p{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }
    }
    .info-detail{
        th {
            width: 50%; 
        }
        td{
            height: 60px;
            img{
                height: 16px;
            }
            span{
                width: 94%;
                margin-left:1%;
                display: inline-block;
                text-align:left;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
            }
        }
    }
</style>